.section-steps {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  &::before,
  &::after {
    content: '';
    background-repeat: repeat-x;
    position: absolute;

    left: 0;
    right: 0;
    width: 100%;
  }
  &::before {
    background-image: url(../../images/wave-top.png);
    height: 150px;
    top: 0;
  }
  &::after {
    background-image: url(../../images/wave-bottom.png);
    height: 139px;
    bottom: 0;

  }
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

}

.steps-background {
  z-index: -1;
  background: linear-gradient(90deg, #fff, #f2ebd8);
}

.grid-steps {
  .grid-item {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% / 3);
    display: inline-flex;
    &:nth-child(1n+4) {
      margin-top: 40px;
    }
  }
}

.card-step {
  display: inline-flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 370px;
  //background-color: rosybrown;
  .card-top {
    display: flex;
    justify-content: end;
    align-items: flex-end;
  }
  .card-body {
    padding: 30px 0;
  }
  .card-title,
  .card-subtitle {
    margin-bottom: 10px;
  }
  .card-subtitle {
    font-size: 22px;
  }
}

@media all and (max-width: 1024px) {

  .section-steps {
    .container {
      padding-bottom: 0;
    }
  }

}

@media all and (max-width: 768px) {
  .grid-steps {
    .grid-item {
      width: calc(100% / 1);
      &:nth-child(1n+1) {
        margin-bottom: 40px;
        margin-top: 0;
      }
    }
  }

  .card-step {
    margin: auto;
    .card-top {
      max-width: 300px;
    }
  }

}

@media all and (max-width: 640px) {
  .section-steps {
    position: relative;
    padding-top: 80px;

  }
}