.section-calculator {
  position: relative;
  padding-top: 80px;
  padding-bottom: 150px;

}

.calculator-background {
  background-image: url("../../images/calculator_bg.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

//.calculator-description {
//  font-size: 24px;
//}

.layout-calculator {
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 40px;
  .layout-item {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% / 2);
    &:nth-child(2) {
      //display: flex;
      //align-items: center;
      //justify-content: center;
    }
  }
  .card-subscription-plan {
    top: 150px;
    margin: auto;
  }
}

.grid-calculator {
  margin-bottom: 50px;
  flex-direction: column;
  .grid-item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

}

.card-calculator {
  .card-title {
    margin-bottom: 10px;
  }
  .card-quantity {
    font-size: 30px;
    text-align: center;
  }
}

.calculator {
  .clue {
    display: flex;
    font-size: 14px;
  }
  .test {

  }
}

.calculator-header {
  margin-bottom: 10px;
}

.calculator-body {
  .calculator-item {
    display: flex;
    //align-items: center;
    flex-direction: row;
    //padding: 26px 0;
    & > div {
      flex-direction: column;
      display: flex;
      justify-content: center;
      padding: 10px;
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 40%;
      }
      & > div:nth-child(1) {
        display: flex;
      }
    }
  }

  .total-plan {
    border-bottom: @form-border;
  }

}

.calculator-footer {

  color: @global-primary-color;
  font-size: 24px;

  .calculator-item {
    display: flex;
    flex-direction: row;
    & > div {
      flex-direction: column;
      display: flex;
      justify-content: center;
      padding: 10px;
      &:nth-child(1) {
        width: 70%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 40%;
      }
      & > div:nth-child(1) {
        display: flex;
      }
    }
  }
}




//************** MY RANGE *****************


@track-width: 100%;
@track-height: 10px;
@track-primary-color: @global-primary-color;
@track-secondary-color: #e0dfdf;






@thumb-color: @global-primary-color;
@thumb-radius: 50%;
@thumb-height: 20px;
@thumb-width: 20px;










input[type="range"] {
  -webkit-appearance: none;
  height: @track-height;
  width: @track-width;
  //background-image: -webkit-linear-gradient(left, @global-primary-color 0%, @global-primary-color 4%, @track-secondary-color 4%, @track-secondary-color 100%);
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: @thumb-color;
    width: @thumb-width;
    height: @thumb-height;
    border-radius: @thumb-radius;
    cursor: pointer;
    transition: .2s;
  }
}


input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: @track-height;
  width: @track-width;
  //background-image: linear-gradient(left, @global-primary-color 0%, @global-primary-color 4%, @track-secondary-color 4%, @track-secondary-color 100%)
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  background-color: @thumb-color;
  width: @thumb-width;
  height: @thumb-height;
  border-radius: @thumb-radius;
  cursor: pointer;

}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  height: @track-height;
  width: @track-width;
  //background-image: -ms-linear-gradient(left, @global-primary-color 0%, @global-primary-color 4%, @track-secondary-color 4%, @track-secondary-color 100%)
}

input[type="range"]::-ms-thumb {
  background-color: @thumb-color;
  width: @thumb-width;
  height: @thumb-height;
  border-radius: @thumb-radius;
  cursor: pointer;
}

input[type="range"]::-ms-fill-lower {
  background-color: @thumb-color;
  width: @thumb-width;
  height: @thumb-height;
  border-radius: @thumb-radius;
  cursor: pointer;
}

input[type="range"]::-ms-fill-upper {
  background-color: @thumb-color;
  width: @thumb-width;
  height: @thumb-height;
  border-radius: @thumb-radius;
  cursor: pointer;
}

//************** END MY RANGE *****************




//************** RANGE *****************

//@track-color: #b7b7b7;
//@thumb-color: @global-primary-color;
//
//@thumb-radius: 50%;
//@thumb-height: 20px;
//@thumb-width: 20px;
//@thumb-shadow-size: 0;
//@thumb-shadow-blur: 0;
//@thumb-shadow-color: #111;
//@thumb-border-width: 1px;
//@thumb-border-color: none;
//
//@track-width: 100%;
//@track-height: 5px;
//@track-shadow-size: 0;
//@track-shadow-blur: 0;
//@track-shadow-color: transparent;
//@track-border-width: 0;
//@track-border-color: none;
//
//@track-radius: 0;
//@contrast: 5%;
//
//.shadow(@shadow-size,@shadow-blur,@shadow-color) {
//  box-shadow: @shadow-size @shadow-size @shadow-blur @shadow-color, 0px 0px @shadow-size lighten(@shadow-color, 5%);
//}
//
//.track() {
//  width: @track-width;
//  height: @track-height;
//  cursor: pointer;
//  animate: 0.2s;
//}
//
//.thumb() {
//  .shadow(@thumb-shadow-size, @thumb-shadow-blur, @thumb-shadow-color);
//  border: @thumb-border-width solid @thumb-border-color;
//  height: @thumb-height;
//  width: @thumb-width;
//  border-radius: @thumb-radius;
//  background: @thumb-color;
//  cursor: pointer;
//}
//
//input[type=range] {
//  -webkit-appearance: none;
//  margin: @thumb-height/2 0;
//  width: @track-width;
//
//  &:focus {
//    outline: none;
//  }
//
//  &::-webkit-slider-runnable-track {
//    .track();
//    .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
//    background: @track-color;
//    border-radius: @track-radius;
//    border: @track-border-width solid @track-border-color;
//  }
//
//  &::-webkit-slider-thumb {
//    .thumb();
//    -webkit-appearance: none;
//    margin-top: ((-@track-border-width * 2 + @track-height) / 2) - (@thumb-height / 2);
//  }
//
//  &:focus::-webkit-slider-runnable-track {
//    background: lighten(@track-color, @contrast);
//  }
//
//  &::-moz-range-track {
//    .track();
//    .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
//    background: @track-color;
//    border-radius: @track-radius;
//    border: @track-border-width solid @track-border-color;
//  }
//  &::-moz-range-thumb {
//    .thumb();
//  }
//
//  &::-ms-track {
//    .track();
//    background: transparent;
//    border-color: transparent;
//    border-width: @thumb-width 0;
//    color: transparent;
//  }
//
//  &::-ms-fill-lower {
//    background: darken(@track-color, @contrast);
//    border: @track-border-width solid @track-border-color;
//    border-radius: @track-radius*2;
//    .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
//  }
//  &::-ms-fill-upper {
//    background: @track-color;
//    border: @track-border-width solid @track-border-color;
//    border-radius: @track-radius*2;
//    .shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
//  }
//  &::-ms-thumb {
//    .thumb();
//  }
//  &:focus::-ms-fill-lower {
//    background: @track-color;
//  }
//  &:focus::-ms-fill-upper {
//    background: lighten(@track-color, @contrast);
//  }
//}

//************** RANGE END  *****************

@media all and (max-width: 992px) {
  .calculator-background {
    background-size: auto;
  }

  .section-calculator {
    padding-bottom: 250px;
    h2 {
      text-align: center;
    }

  }

  .layout-calculator {

    flex-direction: column;
    .layout-item {
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% / 1);
      &:nth-child(1) {
        margin-bottom: 40px;
        max-width: 70%;
        margin-right: auto;
        margin-left: auto;
      }
      &:nth-child(2) {
        //display: flex;
        //align-items: center;
        //justify-content: center;
      }
    }
    .card-subscription-plan {
      //top: 0;
      margin: auto;
    }
  }
}

@media all and (max-width: 768px) {

  .layout-calculator {
    .layout-item {
      &:nth-child(1) {
        max-width: 100%;
      }
    }
  }
}

@media all and (max-width: 640px) {

  .calculator-footer{
    font-size: 20px;
  }
}

@media all and (max-width: 480px) {

  .calculator-footer{
    font-size: 18px;
  }
}