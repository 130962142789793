.section-product-value {
  position: relative;
  padding: 90px 0 40px 0;
}

.product-value-background {
  background-image: url("../../images/product-value_bg.png");
  background-repeat: no-repeat;
  background-position: 80% 60%;
  background-size: contain;
}

.layout-product-value {
  justify-content: space-between;

  .layout-item:first-child {
    width: 60%;
    padding-right: 40px;
  }
  .layout-item:nth-child(2) {
    //width: 40%;
  }
}

@media all and (max-width: 1200px) {

  .layout-product-value {
    .layout-item:nth-child(2) {
      .media {
        max-width: 400px
      }

    }

  }
}

@media all and (max-width: 992px) {

  .product-value-background {
    background-position: 60% 25%;
    background-size:  auto;
  }

  .layout-product-value {
    align-items: center;
    .layout-item:first-child {
      padding-top: 0;
      padding-right: 20px;
    }
    .layout-item:nth-child(2) {
      width: 40%;
    }
  }
}


@media all and (max-width: 768px) {

  .section-product-value {
    padding: 60px 0 40px 0;
  }

  .layout-product-value {
   flex-direction: column-reverse;
    .layout-item:first-child {
      width: auto;
      padding-right: 0;
    }
    .layout-item:nth-child(2) {
      width: auto;
      margin-bottom: 50px;
      .media{
        max-width: 300px;
      }
    }
    //.heading{
    //  text-align: center;
    //}
  }
}