.section-issues {
  position: relative;
  padding: 120px 0 90px 0;
}

.layout-issues {
  justify-content: space-between;

  .layout-item:first-child {
    width: 50%;
  }
  .layout-item:nth-child(2) {
    width: 50%;
    padding-left: 40px;
    padding-top: 50px;
  }
}

.issue-background {
  background-image: url("../../images/issues_bg.png");
  background-repeat: no-repeat;
  background-position: 20% 60%;
  background-size: contain;
}

@media all and (max-width: 1200px) {

  .layout-issues {
    .layout-item:first-child {
      .media {
        max-width: 400px
      }

    }

  }
}

@media all and (max-width: 992px) {
  .issue-background {
    background-position: 20% 35%;
    background-size: auto;
  }

  .layout-issues {
    align-items: center;
    .layout-item:first-child {
      width: 40%;
    }
    .layout-item:nth-child(2) {
      width: 60%;
      padding-top: 0;
      padding-left: 20px;
    }
  }
}

@media all and (max-width: 768px) {

  .section-issues {
    padding: 120px 0 60px 0;
  }

  .layout-issues {
    flex-direction: column;
    align-items: center;
    .layout-item:first-child {
      width: auto;
      margin-bottom: 50px;
      .media {
        max-width: 300px;
      }
    }
    .layout-item:nth-child(2) {
      width: auto;
      padding-left: 0;
      .list-main {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media all and (max-width: 640px) {
  .section-issues {
    padding: 80px 0 60px 0;
  }
}