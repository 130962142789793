*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  box-sizing: border-box;
  font-family: 'ABeeZee', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  color: #333333;
}
body {
  margin: 0;
}
.wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline: none;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  font-family: Consolas, monaco, monospace;
  font-size: 18px;
  color: #f0506e;
  white-space: nowrap;
  padding: 2px 6px;
  background: #f8f8f8;
}
em {
  color: #f0506e;
}
ins {
  background: #333333;
  color: #ffd;
  text-decoration: none;
}
mark {
  background: #ffd;
  color: #333333;
}
q {
  font-style: italic;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
audio,
canvas,
img,
video {
  max-width: 100%;
  height: auto;
}
svg:not(:root) {
  overflow: hidden;
}
img:not([src]) {
  visibility: hidden;
}
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0;
}
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px 0;
  font-family: "Russo One", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
  color: #161616;
  text-transform: normal;
}
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
  margin-top: 20px;
}
h1,
.h1 {
  font-size: 60px;
  line-height: 1.1;
}
h2,
.h2 {
  font-size: 50px;
  line-height: 1.3;
}
h3,
.h3 {
  font-size: 40px;
  line-height: 1.5;
}
h4,
.h4 {
  font-size: 30px;
  line-height: 1.5;
}
h5,
.h5 {
  font-size: 24px;
  line-height: 1.4;
}
h6,
.h6 {
  font-size: 18px;
  line-height: 1.4;
}
ul,
ol {
  padding-left: 30px;
}
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  text-align: inherit;
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #b7b7b7;
}
* + hr {
  margin-top: 20px;
}
address {
  font-style: normal;
}
blockquote {
  margin: 0 0 20px 0;
  font-size: 18px;
  line-height: 1.5;
  font-style: italic;
  color: #333;
}
* + blockquote {
  margin-top: 20px;
}
blockquote p:last-of-type {
  margin-bottom: 0;
}
blockquote footer {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.5;
  color: #666;
}
pre {
  font: 18px / 1.5 Consolas, monaco, monospace;
  color: #333333;
  tab-size: 4;
  overflow: auto;
  padding: 10px;
  border: 1px solid #e5e5e5;
  background: #fff;
}
pre code {
  font-family: Consolas, monaco, monospace;
}
::-moz-selection {
  background: #39f;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none;
}
details,
main {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
iframe {
  border: 0;
}
a,
area,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
[hidden] {
  display: none !important;
}
table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  margin-bottom: 20px;
  background: #fff;
  color: #333333;
}
* + table {
  margin-top: 20px;
}
table caption {
  text-align: left;
}
table td,
table th {
  text-align: left;
  border: 1px solid #b7b7b7;
  border-width: 0 0 1px;
  padding: 16px;
  vertical-align: top;
}
.table-shrink {
  width: 1px;
}
.table-expand {
  min-width: 150px;
}
button,
input[type="button"],
input[type="submit"],
.button {
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  user-select: none;
  border-radius: 0;
  overflow: visible;
  font: inherit;
  text-transform: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 45px;
  font-family: inherit;
  font-size: 18px;
  min-height: 48px;
  text-decoration: none;
  background: none;
  color: #ff0000;
}
.button:not(:disabled) {
  cursor: pointer;
}
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.button:hover {
  text-decoration: none;
  background: none;
  color: #fff;
}
.button:focus {
  outline: none;
  background: none;
  color: #fff;
}
.button:active,
.button.is-active {
  background: none;
  color: #fff;
}
.button:disabled {
  pointer-events: none;
  opacity: 0.45;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="radio"]:not(:disabled),
[type="checkbox"]:not(:disabled) {
  cursor: pointer;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 18px;
  line-height: 1.4;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
::-webkit-datetime-edit,
::-webkit-inner-spin-button {
  height: auto;
}
input:not([type]),
input[type=date],
input[type=datetime-local],
input[type=email],
input[type=file],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type="month"],
input[type=week],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  min-height: 48px;
  background: transparent;
  border: none;
  color: #333333;
  box-shadow: 0 0 0 0 transparent inset;
  border-radius: 0;
}
::placeholder {
  color: #666;
  opacity: 1;
}
input:not([type]):focus,
input[type=date]:focus,
input[type=datetime-local]:focus,
input[type=email]:focus,
input[type=file]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type="month"]:focus,
input[type=week]:focus,
input[type=text]:focus,
input[type=time]:focus,
input[type=url]:focus,
textarea:focus,
select:focus {
  background: transparent;
  color: #333333;
}
input:not([type])[disabled],
input[type=date][disabled],
input[type=datetime-local][disabled],
input[type=email][disabled],
input[type=file][disabled],
input[type=number][disabled],
input[type=password][disabled],
input[type=search][disabled],
input[type=tel][disabled],
input[type="month"][disabled],
input[type=week][disabled],
input[type=text][disabled],
input[type=time][disabled],
input[type=url][disabled],
textarea:disabled,
select:disabled {
  pointer-events: none;
  opacity: 0.45;
  background: #fff;
  color: #666;
}
select:not([multiple]):not([size]) {
  padding-right: 20px;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="black"><path d="M12 1l-3 5h6z"></path><path d="M12 13l-3-5h6z"></path></svg>');
  background-repeat: no-repeat;
  background-position: 100% 50%;
}
select:not([multiple]):not([size])::-ms-expand {
  display: none;
}
select:not([multiple]):not([size]) option {
  color: #444;
}
select:not([multiple]):not([size]):disabled {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="black"><path d="M12 1l-3 5h6z"></path><path d="M12 13l-3-5h6z"></path></svg>');
}
input[type="radio"],
input[type="checkbox"] {
  border: none;
  display: inline-block;
  height: 16px;
  width: 16px;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50% 50%;
}
input[type="radio"] {
  border-radius: 50%;
}
input[type="radio"]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M10 8c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path></svg>');
}
input[type="checkbox"]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" stroke="black"><path fill="none" stroke="inherit" stroke-width="1.8824" stroke-miterlimit="50" stroke-linecap="butt" stroke-linejoin="miter" d="M20.028 2.353l-12.028 12.028-6.381-6.381"></path></svg>');
}
input[type="checkbox"]:indeterminate {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M10 8c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path></svg>');
}
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
}
input[type="radio"]:checked,
input[type="checkbox"]:checked,
input[type="checkbox"]:indeterminate {
  background-color: transparent;
}
input[type="radio"]:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: transparent;
}
input[type="radio"]:disabled:checked {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M10 8c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path></svg>');
}
input[type="checkbox"]:disabled:checked {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" stroke="black"><path fill="none" stroke="inherit" stroke-width="1.8824" stroke-miterlimit="50" stroke-linecap="butt" stroke-linejoin="miter" d="M20.028 2.353l-12.028 12.028-6.381-6.381"></path></svg>');
}
input[type="checkbox"]:disabled:indeterminate {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M3 8h10v1h-10v-1z"></path></svg>');
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  background-color: transparent;
}
input[type="range"] {
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  padding: 0;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-moz-focus-outer {
  border: none;
}
input[type="range"]::-ms-track {
  height: 15px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer;
}
input[type="range"]:not(:disabled)::-moz-range-thumb {
  cursor: pointer;
}
input[type="range"]:not(:disabled)::-ms-thumb {
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: calc(15px / 2 * -1);
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #333333;
}
input[type="range"]::-moz-range-thumb {
  border: none;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #333333;
}
input[type="range"]::-ms-thumb {
  margin-top: 0;
}
input[type="range"]::-ms-thumb {
  border: none;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #333333;
}
input[type="range"]::-ms-tooltip {
  display: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  height: 3px;
  background: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track,
input[type="range"]:active::-webkit-slider-runnable-track {
  background: none;
}
input[type="range"]::-moz-range-track {
  height: 3px;
  background: none;
}
input[type="range"]:focus::-moz-range-track {
  background: none;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  height: 3px;
  background: none;
}
input[type="range"]:focus::-ms-fill-lower,
input[type="range"]:focus::-ms-fill-upper {
  background: none;
}
label.is-invalid {
  color: #b40000;
}
label.is-valid {
  color: #0f9b00;
}
input.is-invalid,
input.is-invalid:focus {
  color: #b40000;
  border-color: #b40000;
}
input.is-valid,
input.is-valid:focus {
  color: #0f9b00;
  border-color: #0f9b00;
}
.form-control {
  margin-bottom: 20px;
}
.form-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #666;
}
.form-icon:hover {
  color: #333333;
}
.form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}
.form-icon:not(.form-icon-flip) ~ input {
  padding-left: 48px !important;
}
.form-icon-flip {
  right: 0;
  left: auto;
}
.form-icon-flip ~ input {
  padding-right: 48px !important;
}
.container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
.container .container {
  padding-left: 0;
  padding-right: 0;
}
.container-expand {
  max-width: none;
}
.section {
  position: relative;
}
.section-overlay,
.section-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.section-foreground {
  position: relative;
  min-height: inherit;
}
.layout {
  display: flex;
}
[class^="icon-"],
[class*=" icon-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
svg {
  max-height: 100%;
  max-width: 100%;
}
svg[class^="icon-"],
svg[class*=" icon-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
body.no-scroll {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}
.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}
.logo {
  display: block;
  padding: 5px 0;
}
.layout-header {
  justify-content: space-between;
  min-height: 100px;
}
.layout-header .layout-item {
  align-items: center;
  display: flex;
}
.layout-header .layout-item:nth-child(1) {
  flex-shrink: 0;
}
.navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.navbar-nav > li {
  position: relative;
}
.navbar-nav > li:not(:last-child) {
  padding-right: 17px;
}
.navbar-nav > li:not(:first-child) {
  padding-left: 17px;
}
.navbar-nav > li:not(:first-child):before {
  content: '';
  width: 2px;
  display: inline-block;
  height: 17px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #333333;
}
.navbar-nav > li > a {
  padding: 0;
}
.navbar-nav .account-link,
.navbar-mobile .account-link {
  color: #ff0000;
  text-transform: uppercase;
}
/*-- ************** for mobile header *****************--*/
.navbar-mobile {
  overflow-y: auto;
  position: fixed;
  width: 100%;
  top: 0;
  margin-top: 70px;
  right: -100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 50px 0 50px;
  transition: right 0.5s ease-in;
}
.navbar-mobile.visible {
  right: 0;
  transition: right 0.5s ease;
}
.navbar-nav-mobile {
  list-style: none;
  margin: 0;
}
.navbar-nav-mobile > li {
  position: relative;
  padding: 15px;
}
.navbar-nav-mobile > li:not(:last-child):after {
  content: '';
  width: 45px;
  display: inline-block;
  border-bottom: 2px dashed #333333;
  position: absolute;
  left: 50%;
  bottom: -1px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.navbar-nav-mobile > li a {
  padding: 10px;
}
/*-- ************** for mobile header *****************--*/
.navbar-mobile-btn {
  position: relative;
  width: 40px;
  height: 41px;
  cursor: pointer;
  display: none;
  z-index: 1;
}
.navbar-mobile-btn span {
  display: inline-block;
  position: absolute;
  width: 30px;
  height: 4px;
  background-color: #ff0000;
  opacity: 1;
  transition: opacity 0.5s ease;
  border-radius: 2px;
}
.navbar-mobile-btn:before,
.navbar-mobile-btn:after {
  content: '';
  /* display: inline-block; */
  width: 30px;
  height: 4px;
  border-radius: 2px;
  background-color: #ff0000;
  position: absolute;
}
.navbar-mobile-btn:before {
  top: 9px;
  transform: rotate(0deg);
  transition: transform 0.25s ease, top 0.25s ease 0.25s;
}
.navbar-mobile-btn:after {
  bottom: 10px;
  transform: rotate(0deg);
  transition: transform 0.25s ease, bottom 0.25s ease 0.25s;
}
.navbar-mobile-btn.open span {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.navbar-mobile-btn.open:before {
  top: 19px;
  transform: rotate(45deg);
  transition: transform 0.25s ease 0.25s, top 0.25s ease;
}
.navbar-mobile-btn.open:after {
  bottom: 19px;
  transform: rotate(-45deg);
  transition: transform 0.25s ease 0.25s, bottom 0.25s ease;
}
/*-- ************** end styles mobile header *****************--*/
@media all and (max-width: 992px) {
  .logo {
    max-width: 250px;
  }
}
@media screen and (min-width: 641px) {
  .navbar-mobile.visible {
    display: none;
  }
}
@media all and (max-width: 640px) {
  .layout-header {
    min-height: 70px;
  }
  .logo {
    max-width: 200px;
  }
  .navbar-nav {
    display: none;
  }
  .navbar-nav-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navbar {
    display: none;
  }
  .navbar-mobile-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    max-width: 200px;
  }
}
.hero {
  position: relative;
  padding-top: 180px;
  padding-bottom: 120px;
}
.hero::after {
  content: '';
  background-image: url(../../images/wave-bottom.png);
  background-repeat: repeat-x;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 139px;
  width: 100%;
}
.hero .hero-img {
  margin-right: calc((100vw - 190%) / -3);
  position: relative;
  z-index: 1;
}
.hero .hero-description {
  font-size: 30px;
  margin-bottom: 60px;
  max-width: 535px;
}
.hero .hero-title {
  margin: 0;
}
.hero .hero-sub-title {
  font-size: 60px;
  color: #161616;
  line-height: 1.1;
  margin-bottom: 30px;
}
.hero-background {
  background: linear-gradient(-108deg, #fff 0%, #f9f6ed 52%, #fff 100%);
}
.layout-hero {
  justify-content: space-between;
}
.layout-hero .layout-item:first-child {
  width: 50%;
  padding-right: 20px;
}
.layout-hero .layout-item:nth-child(2) {
  width: 50%;
}
.hero-action span[class^="icon-play"] {
  font-size: 25px;
  font-weight: bold;
  margin-left: 15px;
}
@media all and (max-width: 1200px) {
  .hero {
    padding-top: 140px;
  }
  .hero .hero-sub-title {
    font-size: 50px;
  }
  .hero .hero-description {
    font-size: 24px;
  }
  .hero .hero-img {
    margin-right: calc((100vw - 100%) / -2);
  }
}
@media all and (max-width: 992px) {
  .layout-hero {
    justify-content: space-between;
  }
  .layout-hero .layout-item:first-child {
    width: 60%;
  }
  .layout-hero .layout-item:nth-child(2) {
    width: 40%;
  }
  .hero {
    padding-top: 120px;
  }
  .hero .hero-img {
    margin-right: calc((100vw - 100%) / -2);
    top: -25px;
  }
  .hero .hero-description {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .hero .hero-sub-title {
    margin-bottom: 20px;
  }
}
@media all and (max-width: 768px) {
  .hero .hero-sub-title {
    font-size: 38px;
    margin-bottom: 0;
  }
  .hero .hero-img {
    margin-right: calc((100vw - 90%) / -2);
  }
}
@media all and (max-width: 640px) {
  .hero .hero-sub-title {
    font-size: 35px;
  }
  .hero .hero-content {
    padding-right: 0;
    text-align: center;
  }
  .hero .hero-description {
    max-width: 100%;
  }
  .layout-hero {
    flex-direction: column-reverse;
  }
  .layout-hero .layout-item:nth-child(n+1) {
    width: 100%;
  }
}
.section-issues {
  position: relative;
  padding: 120px 0 90px 0;
}
.layout-issues {
  justify-content: space-between;
}
.layout-issues .layout-item:first-child {
  width: 50%;
}
.layout-issues .layout-item:nth-child(2) {
  width: 50%;
  padding-left: 40px;
  padding-top: 50px;
}
.issue-background {
  background-image: url("../../images/issues_bg.png");
  background-repeat: no-repeat;
  background-position: 20% 60%;
  background-size: contain;
}
@media all and (max-width: 1200px) {
  .layout-issues .layout-item:first-child .media {
    max-width: 400px;
  }
}
@media all and (max-width: 992px) {
  .issue-background {
    background-position: 20% 35%;
    background-size: auto;
  }
  .layout-issues {
    align-items: center;
  }
  .layout-issues .layout-item:first-child {
    width: 40%;
  }
  .layout-issues .layout-item:nth-child(2) {
    width: 60%;
    padding-top: 0;
    padding-left: 20px;
  }
}
@media all and (max-width: 768px) {
  .section-issues {
    padding: 120px 0 60px 0;
  }
  .layout-issues {
    flex-direction: column;
    align-items: center;
  }
  .layout-issues .layout-item:first-child {
    width: auto;
    margin-bottom: 50px;
  }
  .layout-issues .layout-item:first-child .media {
    max-width: 300px;
  }
  .layout-issues .layout-item:nth-child(2) {
    width: auto;
    padding-left: 0;
  }
  .layout-issues .layout-item:nth-child(2) .list-main {
    display: flex;
    flex-direction: column;
  }
}
@media all and (max-width: 640px) {
  .section-issues {
    padding: 80px 0 60px 0;
  }
}
.section-product-value {
  position: relative;
  padding: 90px 0 40px 0;
}
.product-value-background {
  background-image: url("../../images/product-value_bg.png");
  background-repeat: no-repeat;
  background-position: 80% 60%;
  background-size: contain;
}
.layout-product-value {
  justify-content: space-between;
}
.layout-product-value .layout-item:first-child {
  width: 60%;
  padding-right: 40px;
}
@media all and (max-width: 1200px) {
  .layout-product-value .layout-item:nth-child(2) .media {
    max-width: 400px;
  }
}
@media all and (max-width: 992px) {
  .product-value-background {
    background-position: 60% 25%;
    background-size: auto;
  }
  .layout-product-value {
    align-items: center;
  }
  .layout-product-value .layout-item:first-child {
    padding-top: 0;
    padding-right: 20px;
  }
  .layout-product-value .layout-item:nth-child(2) {
    width: 40%;
  }
}
@media all and (max-width: 768px) {
  .section-product-value {
    padding: 60px 0 40px 0;
  }
  .layout-product-value {
    flex-direction: column-reverse;
  }
  .layout-product-value .layout-item:first-child {
    width: auto;
    padding-right: 0;
  }
  .layout-product-value .layout-item:nth-child(2) {
    width: auto;
    margin-bottom: 50px;
  }
  .layout-product-value .layout-item:nth-child(2) .media {
    max-width: 300px;
  }
}
.section-steps {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
}
.section-steps::before,
.section-steps::after {
  content: '';
  background-repeat: repeat-x;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}
.section-steps::before {
  background-image: url(../../images/wave-top.png);
  height: 150px;
  top: 0;
}
.section-steps::after {
  background-image: url(../../images/wave-bottom.png);
  height: 139px;
  bottom: 0;
}
.section-steps .container {
  padding-top: 100px;
  padding-bottom: 100px;
}
.steps-background {
  z-index: -1;
  background: linear-gradient(90deg, #fff, #f2ebd8);
}
.grid-steps .grid-item {
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% / 3);
  display: inline-flex;
}
.grid-steps .grid-item:nth-child(1n+4) {
  margin-top: 40px;
}
.card-step {
  display: inline-flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 370px;
}
.card-step .card-top {
  display: flex;
  justify-content: end;
  align-items: flex-end;
}
.card-step .card-body {
  padding: 30px 0;
}
.card-step .card-title,
.card-step .card-subtitle {
  margin-bottom: 10px;
}
.card-step .card-subtitle {
  font-size: 22px;
}
@media all and (max-width: 1024px) {
  .section-steps .container {
    padding-bottom: 0;
  }
}
@media all and (max-width: 768px) {
  .grid-steps .grid-item {
    width: calc(100% / 1);
  }
  .grid-steps .grid-item:nth-child(1n+1) {
    margin-bottom: 40px;
    margin-top: 0;
  }
  .card-step {
    margin: auto;
  }
  .card-step .card-top {
    max-width: 300px;
  }
}
@media all and (max-width: 640px) {
  .section-steps {
    position: relative;
    padding-top: 80px;
  }
}
.section-subscription-plan {
  position: relative;
  padding: 90px 0 120px 0;
}
.section-subscription-plan .card-free {
  max-width: 600px;
  margin: auto;
  text-align: center;
  margin-bottom: 60px;
}
.section-subscription-plan .card-free .card-description {
  font-size: 24px;
}
.section-subscription-plan .card-free .card-or {
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 10px;
}
.section-subscription-plan .card-free .card-actions {
  margin-bottom: 50px;
}
.section-subscription-plan .img-man {
  position: absolute;
  bottom: 70%;
  left: -6%;
  z-index: -1;
}
.subscription-plan-background {
  background-image: url("../../images/subscription-plan_bg.png");
  background-repeat: no-repeat;
  background-position: 50% 60%;
  background-size: contain;
  z-index: -2;
}
.grid-subscription-plan {
  position: relative;
}
.grid-subscription-plan .grid-item {
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% / 4);
}
.grid-subscription-plan .grid-item:nth-child(1n+5) {
  margin-bottom: 60px;
}
.card-subscription-plan {
  position: relative;
  max-width: 270px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-radius: 4px;
}
.card-subscription-plan.is-inverse {
  border: 1px solid #ff0000;
  margin-top: -30px;
  margin-bottom: -30px;
  padding-top: 0;
  padding-bottom: 70px;
}
.card-subscription-plan .media-top {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100px;
}
.card-subscription-plan .media-right,
.card-subscription-plan .media-left {
  position: absolute;
  top: 20px;
  max-width: 30px;
}
.card-subscription-plan .media-left {
  right: -15px;
}
.card-subscription-plan .media-right {
  left: -15px;
}
.card-subscription-plan .card-choice {
  color: #fff;
  height: 30px;
  width: 80%;
  background-color: #ff0000;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
}
.card-subscription-plan .card-choice::before {
  content: '';
  border: 15px solid transparent;
  border-right: 15px solid #ff0000;
  border-top: 15px solid #ff0000;
  left: -30px;
  position: absolute;
}
.card-subscription-plan .card-choice::after {
  content: '';
  border: 15px solid transparent;
  border-left: 15px solid #ff0000;
  border-top: 15px solid #ff0000;
  right: -30px;
  position: absolute;
}
.card-subscription-plan .card-choice .card-title {
  font-size: 14px;
  letter-spacing: 1px;
}
.card-subscription-plan .card-body {
  margin-bottom: 20px;
}
.card-subscription-plan .card-title {
  font-size: 24px;
}
.card-subscription-plan .card-price {
  color: #ff0000;
  font-size: 40px;
  font-family: "Russo One", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 10px;
}
.card-subscription-plan .card-quantity {
  font-size: 36px;
  margin: -8px 0;
}
@media all and (max-width: 1200px) {
  .section-subscription-plan .img-man {
    bottom: 80%;
    left: -4%;
    max-width: 300px;
  }
}
@media all and (max-width: 992px) {
  .subscription-plan-background {
    background-size: auto;
  }
  .section-subscription-plan .img-man {
    display: none;
  }
  .grid-subscription-plan .grid-item {
    width: calc(100% / 2);
  }
  .grid-subscription-plan .grid-item:nth-child(1n+1) {
    margin-bottom: 60px;
    margin-top: 0;
  }
  .card-subscription-plan {
    margin: auto;
  }
}
@media all and (max-width: 640px) {
  .section-subscription-plan {
    padding: 90px 0 50px 0;
  }
  .section-subscription-plan .card-free .card-description {
    font-size: 22px;
  }
  .section-subscription-plan .card-free .card-or {
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 0;
  }
  .section-subscription-plan .card-free .card-actions {
    margin-bottom: 40px;
  }
  .section-subscription-plan .img-man {
    position: absolute;
    bottom: 70%;
    left: -6%;
    z-index: -1;
  }
}
@media all and (max-width: 480px) {
  .grid-subscription-plan .grid-item {
    width: 100%;
  }
  .card-subscription-plan.is-inverse {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.section-calculator {
  position: relative;
  padding-top: 80px;
  padding-bottom: 150px;
}
.calculator-background {
  background-image: url("../../images/calculator_bg.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.layout-calculator {
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 40px;
}
.layout-calculator .layout-item {
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% / 2);
}
.layout-calculator .card-subscription-plan {
  top: 150px;
  margin: auto;
}
.grid-calculator {
  margin-bottom: 50px;
  flex-direction: column;
}
.grid-calculator .grid-item:not(:last-child) {
  margin-bottom: 30px;
}
.card-calculator .card-title {
  margin-bottom: 10px;
}
.card-calculator .card-quantity {
  font-size: 30px;
  text-align: center;
}
.calculator .clue {
  display: flex;
  font-size: 14px;
}
.calculator-header {
  margin-bottom: 10px;
}
.calculator-body .calculator-item {
  display: flex;
  flex-direction: row;
}
.calculator-body .calculator-item > div {
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.calculator-body .calculator-item > div:nth-child(1) {
  width: 30%;
}
.calculator-body .calculator-item > div:nth-child(2) {
  width: 10%;
}
.calculator-body .calculator-item > div:nth-child(3) {
  width: 30%;
}
.calculator-body .calculator-item > div:nth-child(4) {
  width: 10%;
}
.calculator-body .calculator-item > div:nth-child(5) {
  width: 40%;
}
.calculator-body .calculator-item > div > div:nth-child(1) {
  display: flex;
}
.calculator-body .total-plan {
  border-bottom: 1px solid #b7b7b7;
}
.calculator-footer {
  color: #ff0000;
  font-size: 24px;
}
.calculator-footer .calculator-item {
  display: flex;
  flex-direction: row;
}
.calculator-footer .calculator-item > div {
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.calculator-footer .calculator-item > div:nth-child(1) {
  width: 70%;
}
.calculator-footer .calculator-item > div:nth-child(2) {
  width: 10%;
}
.calculator-footer .calculator-item > div:nth-child(3) {
  width: 40%;
}
.calculator-footer .calculator-item > div > div:nth-child(1) {
  display: flex;
}
input[type="range"] {
  -webkit-appearance: none;
  height: 10px;
  width: 100%;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
}
input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 10px;
  width: 100%;
}
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  -webkit-appearance: none;
  height: 10px;
  width: 100%;
}
input[type="range"]::-ms-thumb {
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
input[type="range"]::-ms-fill-upper {
  background-color: #ff0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
@media all and (max-width: 992px) {
  .calculator-background {
    background-size: auto;
  }
  .section-calculator {
    padding-bottom: 250px;
  }
  .section-calculator h2 {
    text-align: center;
  }
  .layout-calculator {
    flex-direction: column;
  }
  .layout-calculator .layout-item {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% / 1);
  }
  .layout-calculator .layout-item:nth-child(1) {
    margin-bottom: 40px;
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
  .layout-calculator .card-subscription-plan {
    margin: auto;
  }
}
@media all and (max-width: 768px) {
  .layout-calculator .layout-item:nth-child(1) {
    max-width: 100%;
  }
}
@media all and (max-width: 640px) {
  .calculator-footer {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  .calculator-footer {
    font-size: 18px;
  }
}
.section-cta {
  padding-bottom: 40px;
}
.card-cta .card-top {
  text-align: center;
  margin-bottom: 100px;
}
.card-cta .card-title {
  font-size: 36px;
  color: #161616;
  margin-bottom: 15px;
}
.card-cta .card-description {
  font-size: 24px;
}
@media all and (max-width: 992px) {
  .card-cta .card-top {
    text-align: center;
    margin-bottom: 100px;
  }
}
@media all and (max-width: 640px) {
  .section-cta {
    padding-bottom: 0;
  }
  .card-cta .card-top {
    text-align: center;
    margin-bottom: 60px;
  }
  .card-cta .card-title {
    font-size: 30px;
  }
  .card-cta .card-description {
    font-size: 22px;
  }
}
.footer {
  position: relative;
  padding-top: 180px;
  padding-bottom: 60px;
}
.footer-background {
  background: linear-gradient(90deg, #fff, #f2ebd8);
}
.footer-background::before {
  content: '';
  background-repeat: repeat-x;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-image: url(../../images/wave-top.png);
  height: 150px;
}
.footer-background2 {
  background-image: url("../../images/footer_bg.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.layout-prefooter {
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 50px;
}
.layout-prefooter .layout-item {
  padding-left: 15px;
  padding-right: 15px;
  width: calc(100% / 2);
}
.layout-prefooter .grid-contact {
  display: flex;
  flex-direction: column;
}
.layout-prefooter .grid-contact .grid-item:not(:last-child) {
  margin-bottom: 20px;
}
.layout-prefooter h2 {
  text-align: center;
  margin-bottom: 40px;
}
.card-contact .card-title {
  font-size: 24px;
}
.card-contact .card-list {
  padding-left: 0;
  list-style: none;
}
.card-contact .card-list li {
  display: flex;
  align-items: center;
}
.card-contact .card-list li:not(:last-child) {
  margin-bottom: 12px;
}
.card-contact .card-label {
  margin-right: 10px;
  text-transform: uppercase;
}
.social-list {
  margin-bottom: 30px;
  padding-left: 0;
  list-style: none;
  display: flex;
}
.social-list > li:not(:last-child) {
  margin-right: 20px;
}
.social-list a span {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 40px;
  padding: 10px;
  border-radius: 50%;
  color: #161616;
  position: relative;
  transition: all ease 0.2s;
}
.social-list a span::before {
  z-index: 1;
}
.social-list a span::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  z-index: 0;
  border-radius: 50px;
  background-color: rgba(22, 22, 22, 0.1);
}
.social-list a span:hover {
  color: #fff;
  font-size: 18px;
}
.social-list a span:hover::after {
  animation: animate ease 0.6s forwards;
}
.social-list a span.icon-facebook:hover::after {
  background-color: #3b5998;
}
.social-list a span.icon-twitter:hover::after {
  background-color: #00aced;
}
.social-list a span.icon-youtube-play:hover::after {
  background-color: #bb0000;
}
.social-list a span.icon-linkedin:hover::after {
  background-color: #007bb6;
}
.social-list a span.icon-google-plus:hover::after {
  background-color: #dd4b39;
}
@keyframes animate {
  0%,
  20% {
    transform: scale(1);
    border-radius: 30px;
    background-color: #9E9E9E;
  }
  30% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
    border-radius: 10px;
  }
  60%,
  100% {
    transform: scale(1);
    border-radius: 5px;
  }
}
.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}
.footer-bottom .copyright {
  font-size: 14px;
}
@media all and (max-width: 1200px) {
  .card-contact .card-list {
    font-size: 16px;
  }
}
@media all and (max-width: 992px) {
  .card-contact .card-list {
    font-size: 14px;
  }
}
@media all and (max-width: 850px) {
  .footer-background2 {
    background-position: 40% 75%;
    background-size: auto;
  }
  .layout-prefooter {
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }
  .layout-prefooter .layout-item {
    width: calc(100% / 1);
  }
  .layout-prefooter .layout-item:nth-child(2) {
    max-width: 80%;
    margin: auto;
    margin-bottom: 70px;
  }
  .layout-prefooter .grid-contact {
    display: flex;
    flex-direction: column;
  }
  .layout-prefooter .grid-contact .grid-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .layout-prefooter h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  .card-contact {
    text-align: center;
  }
  .card-contact .card-list li {
    justify-content: center;
  }
}
@media all and (max-width: 768px) {
  .footer {
    padding-top: 140px;
  }
}
@media all and (max-width: 640px) {
  .footer {
    padding-bottom: 40px;
  }
  .layout-prefooter {
    margin-bottom: 40px;
  }
  .layout-prefooter .layout-item {
    width: calc(100% / 1);
  }
  .layout-prefooter .layout-item:nth-child(2) {
    max-width: 100%;
  }
}
@media all and (max-width: 480px) {
  .card-contact .card-list {
    font-size: 12px;
  }
  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
  .footer-bottom .copyright {
    font-size: 12px;
  }
}
.section-account {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
}
.section-account::after {
  content: '';
  background-image: url(../../images/wave-bottom.png);
  background-repeat: repeat-x;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 139px;
  width: 100%;
}
.layout-account {
  justify-content: flex-start;
}
.layout-account .layout-item:first-child {
  width: 300px;
  min-width: 250px;
}
.layout-account .layout-item:first-child .media {
  margin-top: 50px;
}
.layout-account .layout-item:nth-child(2) {
  width: 100%;
  max-width: 700px;
}
.account-background {
  background: linear-gradient(-108deg, #fff 0%, #f9f6ed 52%, #fff 100%);
}
.form-account {
  padding: 60px 70px 30px 70px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}
.form-account .form-header,
.form-account .form-footer {
  text-align: center;
}
.form-account .form-header {
  margin-bottom: 30px;
}
.form-account .sup-form-text {
  font-size: 14px;
  color: #666;
}
.form-account .form-actions {
  margin-bottom: 30px;
}
.form-account .form-help {
  font-size: 16px;
}
.form-account .form-help .description {
  margin-bottom: 10px;
}
.form-account .form-help a {
  text-transform: uppercase;
  color: #ff0000;
}
@media all and (max-width: 850px) {
  .layout-account .layout-item:first-child {
    min-width: 200px;
  }
  .form-account {
    padding: 50px 40px 30px 40px;
  }
}
@media all and (max-width: 767px) {
  .layout-account {
    justify-content: center;
  }
  .layout-account .layout-item:first-child {
    display: none;
  }
}
@media all and (max-width: 480px) {
  .form-account {
    padding: 50px 20px 30px 20px;
  }
}
button,
input[type="button"],
input[type="submit"],
.button {
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  user-select: none;
  overflow: hidden;
  font: inherit;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 45px;
  font-family: inherit;
  font-size: 18px;
  min-height: 48px;
  text-decoration: none;
  background: none;
  color: #ff0000;
  border: 2px solid #ff0000;
  border-radius: 5px;
  transition: all 0.2s ease;
  margin-top: 30px;
  z-index: 1;
}
button.button-lg,
input[type="button"].button-lg,
input[type="submit"].button-lg,
.button.button-lg {
  padding: 6px 50px;
}
button::after,
input[type="button"]::after,
input[type="submit"]::after,
.button::after {
  position: absolute;
  transition: 0.3s;
  content: '';
  width: 0;
  left: -35px;
  background: #ff0000;
  height: 120%;
  -webkit-transform: skewX(50deg);
  transform: skewX(50deg);
  z-index: -1;
  bottom: -2px;
  top: -2px;
}
button:hover::after,
input[type="button"]:hover::after,
input[type="submit"]:hover::after,
.button:hover::after,
button:focus::after,
input[type="button"]:focus::after,
input[type="submit"]:focus::after,
.button:focus::after,
button:active::after,
input[type="button"]:active::after,
input[type="submit"]:active::after,
.button:active::after {
  width: 104%;
}
input:not([type]),
input[type=date],
input[type=datetime-local],
input[type=email],
input[type=file],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type="month"],
input[type=week],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 5px;
  min-height: 48px;
  background: transparent;
  border-bottom: 1px solid #b7b7b7;
  color: #333333;
  box-shadow: 0 0 0 0 transparent inset;
  border-radius: 0;
}
::placeholder {
  color: #666;
  opacity: 1;
}
input:not([type]):focus,
input[type=date]:focus,
input[type=datetime-local]:focus,
input[type=email]:focus,
input[type=file]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type="month"]:focus,
input[type=week]:focus,
input[type=text]:focus,
input[type=time]:focus,
input[type=url]:focus,
textarea:focus,
select:focus {
  background: transparent;
  color: #333333;
}
input[type="radio"],
input[type="checkbox"] {
  display: inline-block;
  height: 16px;
  width: 16px;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50% 50%;
  border: 1px solid #b7b7b7;
}
lable {
  color: #333333;
}
.form-group {
  position: relative;
  margin-bottom: 40px;
}
.form-group.error input {
  border-bottom: 1px solid #b40000;
}
.form-group.error .form-message {
  color: #b40000;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.form-group.success input {
  border-bottom: 1px solid #0f9b00;
}
.form-group.success .label-materials {
  color: #0f9b00;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  font-size: 18px;
}
.label-materials {
  position: absolute;
  left: 5px;
  top: 10px;
  pointer-events: none;
  transition: all 0.2s;
}
.label-materials.label-materials-is-active {
  top: -20px;
  font-size: 14px;
}
form {
  font-size: 16px;
}
textarea {
  resize: none;
}
.overlay,
.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.65);
}
.foreground {
  position: relative;
  z-index: 1;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
h2.is-inverse {
  font-family: 'ABeeZee', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
h2,
h3,
h4 {
  text-transform: uppercase;
}
.big-letter {
  text-transform: uppercase;
}
.heading {
  margin-bottom: 30px;
}
.heading.heading-width {
  max-width: 920px;
}
.heading.center {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
.heading.m-b-lg {
  margin-bottom: 50px;
}
.card {
  width: 100%;
}
.list-main {
  list-style-type: none;
  padding: 0;
}
.list-main li {
  display: inline-flex;
}
.list-main li::before {
  margin-right: 10px;
  content: '';
  display: flex;
  margin-top: 6px;
  /* padding-top: 23px; */
  align-items: stretch;
  height: 16px;
  /* align-items: center; */
  width: 18px;
  padding: 6px;
  background-repeat: no-repeat;
  background-image: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAh1BMVEUWFhb/////AAAWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhb5AQH/AAD/AAD/AAD/AAD/AAAWFhb/AAAWFhYWFhb/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAAWFhZiDw/IBQWYCgr/AABgDw/KBQUAAADPU7E7AAAAJXRSTlMAAABPwPW+TImBSrn27vT+9sBNhEqCubvv7MO4TEOH/n1Juu23b49f2AAAAAFiS0dELLrdcasAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfiCgQQBQbauG3fAAAAdElEQVQI113ORwKCUBRD0fxQRaWIDRWwgIDuf3/+9ibe2RklAIIwiuIEOqUUkI62lXcw+jLnULymCRvx1jl3ek9FWe20Y8t5sdVEYvhZfHvgoP0VH/VqdjoX4sbcIss/X8RX84m8iVtndlb9nd58PF/N0JI/CO0Uon54WrAAAAAASUVORK5CYII=);
}
.list-main li:not(:last-child) {
  margin-bottom: 30px;
}
.scroll-top {
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 17px;
  background-color: #f9f6ed;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  right: 15px;
  bottom: 80px;
  transform: translate(0, 200px);
  transition: all 0.3s ease-out;
  border-radius: 3px;
}
.scroll-top.active {
  transform: none;
}
body {
  overflow-x: hidden;
}
@media all and (max-width: 1200px) {
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 48px;
  }
}
@media all and (max-width: 850px) {
  .list-main li:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media all and (max-width: 768px) {
  button.button-big,
  input[type="button"].button-big,
  input[type="submit"].button-big,
  .button.button-big {
    padding: 6px 45px;
  }
  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 38px;
  }
}
@media all and (max-width: 640px) {
  h1 {
    font-size: 38px;
  }
  h1 {
    font-size: 35px;
  }
}
