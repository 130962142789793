body{
  &.no-scroll{
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
  }
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}

.logo {
  display: block;
  padding: 5px 0;
}

.layout-header {
  justify-content: space-between;
  min-height: 100px;

  .layout-item {
    align-items: center;
    display: flex;
  }

  .layout-item:nth-child(1) {
    flex-shrink: 0;
  }

}

.navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > li {
    position: relative;

    &:not(:last-child) {
      padding-right: 17px;
    }

    &:not(:first-child) {
      padding-left: 17px;

      &:before {
        content: '';
        width: 2px;
        display: inline-block;
        height: 17px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        background-color: @global-color;
      }
    }
  }


  & > li > a {
    padding: 0;
  }

}


.navbar-nav,
.navbar-mobile{
  .account-link {
    color: @global-primary-color;
    text-transform: uppercase;
  }
}

/*-- ************** for mobile header *****************--*/

.navbar-mobile {
  overflow-y: auto;
  position: fixed;
  width: 100%;
  top: 0;
  margin-top: 70px;
  right: -100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 50px 0 50px;
  transition: right 0.5s ease-in;

  &.visible {
    right: 0;
    transition: right .5s ease;

  }
}

.navbar-nav-mobile {
  list-style: none;
  margin: 0;
  & > li {
    position: relative;
    padding: 15px;


    &:not(:last-child) {

      &:after{
        content: '';
        width: 45px;
        display: inline-block;
        border-bottom: 2px dashed @global-color;
        position: absolute;
        left: 50%;
        bottom: -1px;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
      }
    }

    a{
      padding: 10px;
    }
  }
}

/*-- ************** for mobile header *****************--*/
.navbar-mobile-btn {
  position: relative;
  width: 40px;
  height: 41px;
  //display: flex;
  cursor: pointer;
  display: none;
  z-index: 1;

  span {
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 4px;
    background-color: @global-primary-color;
    opacity: 1;
    transition: opacity 0.5s ease;
    border-radius: 2px;
  }

  &:before,
  &:after {
    content: '';
    /* display: inline-block; */
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: @global-primary-color;
    position: absolute;
  }

  &:before {
    top: 9px;
    transform: rotate(0deg);
    transition: transform 0.25s ease, top 0.25s ease 0.25s;
  }

  &:after {

    bottom: 10px;
    transform: rotate(0deg);
    transition: transform 0.25s ease, bottom 0.25s ease 0.25s;
  }

  &.open {

    span {
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    &:before {
      top: 19px;
      transform: rotate(45deg);
      transition: transform 0.25s ease 0.25s, top 0.25s ease;
    }

    &:after {
      bottom: 19px;
      transform: rotate(-45deg);
      transition: transform 0.25s ease 0.25s, bottom 0.25s ease;
    }
  }
}
/*-- ************** end styles mobile header *****************--*/

@media all and (max-width: 992px) {
  .logo {
    max-width: 250px;
  }
}



@media screen and (min-width: 641px) {

  .navbar-mobile {
    &.visible {
      display: none;

    }
  }

}

@media all and (max-width:640px) {

  .layout-header{
    min-height: 70px;
  }

  .logo {
    max-width: 200px;
  }

  .navbar-nav{
    display: none;
  }

  .navbar-nav-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar {
    display: none;
  }

  .navbar-mobile-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    max-width: 200px;
  }
}













