.section-subscription-plan {
  position: relative;
  padding: 90px 0 120px 0;

  .card-free {
    max-width: 600px;
    margin: auto;
    text-align: center;
    margin-bottom: 60px;
    .card-description {
      font-size: 24px;
    }
    .card-or {
      text-transform: uppercase;
      font-size: 30px;
      margin-bottom: 10px;
    }
    .card-actions {
      margin-bottom: 50px;
    }
  }
  .img-man {
    position: absolute;
    bottom: 70%;
    left: -6%;
    z-index: -1;
    //max-width: 255px;

  }
}

.subscription-plan-background {
  background-image: url("../../images/subscription-plan_bg.png");
  background-repeat: no-repeat;
  background-position: 50% 60%;
  background-size: contain;
  z-index: -2;
}

//.layout-product-value {
//  justify-content: space-between;
//
//  .layout-item:first-child {
//    width: 60%;
//    padding-right: 40px;
//  }
//  .layout-item:nth-child(2) {
//    //width: 40%;
//  }
//}

.grid-subscription-plan {
  position: relative;
  .grid-item {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% / 4);
    //display: inline-flex;
    &:nth-child(1n+5) {
      margin-bottom: 60px;
    }
  }
}

.card-subscription-plan {
  position: relative;
  max-width: 270px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;
  padding: 40px 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .15);
  background-color: @global-background;
  border-radius: 4px;

  &.is-inverse {
    border: 1px solid @global-primary-color;
    margin-top: -30px;
    margin-bottom: -30px;
    padding-top: 0;
    padding-bottom: 70px;
  }

  .media-top {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100px;
  }
  .media-right,
  .media-left {
    position: absolute;
    top: 20px;
    max-width: 30px;
  }
  .media-left {
    right: -15px;
  }
  .media-right {
    left: -15px;
  }
  .card-choice {
    color: @global-inverse-color;
    height: 30px;
    width: 80%;
    background-color: @global-primary-color;
    //text-align: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
    &::before {
      content: '';
      border: 15px solid transparent;
      border-right: 15px solid @global-primary-color;
      border-top: 15px solid @global-primary-color;
      left: -30px;
      position: absolute;
    }
    &::after {
      content: '';
      border: 15px solid transparent;
      border-left: 15px solid @global-primary-color;
      border-top: 15px solid @global-primary-color;
      right: -30px;
      position: absolute;
    }

    .card-title {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }

  .card-body {
    margin-bottom: 20px;
  }
  .card-title {
    font-size: 24px;
  }
  .card-price {
    color: @global-primary-color;
    font-size: 40px;
    font-family: @global-primary-font-family;
    margin-bottom: 10px;
  }
  .card-quantity {
    font-size: 36px;
    margin: -8px 0;
  }
}

@media all and (max-width: 1200px) {
  .section-subscription-plan {
    .img-man {
      bottom: 80%;
      left: -4%;
      max-width: 300px;
    }
  }
}

@media all and (max-width: 992px) {

  .subscription-plan-background{
    background-size:  auto;
  }
  .section-subscription-plan {
    .img-man {
      display: none;
    }
  }

  .grid-subscription-plan {
    .grid-item {
      width: calc(100% / 2);
      &:nth-child(1n+1) {
        margin-bottom: 60px;
        margin-top: 0;
      }
    }
  }

  .card-subscription-plan {
    margin: auto;
  }

}

@media all and (max-width: 640px) {

  .section-subscription-plan {
    padding: 90px 0 50px 0;
    .card-free {
      .card-description {
        font-size: 22px;
      }
      .card-or {
        text-transform: uppercase;
        font-size: 26px;
        margin-bottom: 0;
      }
      .card-actions{
        margin-bottom: 40px;
      }
    }
    .img-man {
      position: absolute;
      bottom: 70%;
      left: -6%;
      z-index: -1;
      //max-width: 255px;

    }
  }

}



@media all and (max-width: 480px) {

  .grid-subscription-plan {
    .grid-item {
      width: 100%;
    }
  }

  .card-subscription-plan {
    &.is-inverse {
      margin-top: 0;
      margin-bottom: 0;
    }

  }

}