.section-cta {
  padding-bottom: 40px;
}

.card-cta {

  .card-top {
    text-align: center;
    margin-bottom: 100px;
  }

  .card-title {
    font-size: 36px;
    color: @base-heading-color;
    margin-bottom: 15px;

  }

  .card-description {
    font-size: 24px;
    //margin-bottom: 20px;
  }

}
@media all and (max-width:992px){
  .card-cta{
    .card-top {
      text-align: center;
      margin-bottom: 100px;
    }
  }
}

@media all and (max-width:640px){
  .section-cta{
    padding-bottom: 0;
  }
  .card-cta {
    .card-top {
      text-align: center;
      margin-bottom: 60px;
    }
    .card-title {
      font-size: 30px;
    }
    .card-description {
      font-size: 22px;
    }

  }
}