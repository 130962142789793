.hero {
  position: relative;
  padding-top: 180px;
  padding-bottom: 120px;
  //min-height: calc(100vh - 210px);
  &::after {
    content: '';
    background-image: url(../../images/wave-bottom.png);
    background-repeat: repeat-x;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 139px;
    width: 100%;
  }

  .hero-img {
    margin-right: calc((100vw - 190%) / -3);
    position: relative;
    //top: 30px;
    z-index: 1;
  }

  .hero-description {
    font-size: 30px;
    margin-bottom: 60px;
    max-width: 535px;
  }

  .hero-title {
    margin: 0;
  }

  .hero-sub-title {
    font-size: @base-h1-font-size;
    color: @base-heading-color;
    line-height: @base-h1-line-height;
    margin-bottom: 30px;
  }

}

.hero-background {
  //background: linear-gradient(-93deg, #fff, #f2ebd8);
  background: linear-gradient(-108deg, #fff 0%, #f9f6ed 52%, #fff 100%);
}

.layout-hero {
  justify-content: space-between;

  .layout-item {
    &:first-child {
      width: 50%;
      padding-right: 20px;
    }

    &:nth-child(2) {
      width: 50%;
    }
  }

}

.hero-action {
  span[class^="icon-play"] {
    font-size: 25px;
    font-weight: bold;
    margin-left: 15px;
  }


}

@media all and (max-width: 1366px) {
  .hero {
    .hero-img {
      //margin-right: calc((100vw - 100%) / -3);
      //margin-right: calc(100vw / -2);
    }
  }
}

@media all and (max-width: 1200px) {
  .hero {
    padding-top: 140px;

    .hero-sub-title {
      font-size: 50px;
    }

    .hero-description {
      font-size: 24px;
    }

    .hero-img {
      margin-right: calc((100vw - 100%) / -2);
    }
  }
}

@media all and (max-width: 992px) {

  .layout-hero {
    justify-content: space-between;

    .layout-item {
      &:first-child {
        width: 60%;
      }

      &:nth-child(2) {
        width: 40%;
      }
    }
  }

  .hero {
    padding-top: 120px;

    .hero-img {
      margin-right: calc((100vw - 100%) / -2);
      top: -25px;
    }

    .hero-description {
      font-size: 20px;
      margin-bottom: 40px;
    }

    .hero-sub-title {
      margin-bottom: 20px;
    }
  }
}

@media all and (max-width: 768px) {
  .hero {
    .hero-sub-title {
      font-size: 38px;
      margin-bottom: 0;
    }

    .hero-img {
      margin-right: calc((100vw - 90%) / -2);
    }
  }
}

@media all and (max-width: 640px) {
  .hero {
    .hero-sub-title {
      font-size: 35px;
    }

    .hero-img {
      //margin: 0 auto 20px;
    }

    .hero-content {
      padding-right: 0;
      text-align: center;
    }

    .hero-description {
      max-width: 100%;
    }
  }

  .layout-hero {
    flex-direction: column-reverse;

    .layout-item {
      &:nth-child(n+1) {
        width: 100%;
      }
    }
  }

}


