
.footer {
  position: relative;
  padding-top: 180px;
  padding-bottom: 60px;
}

.footer-background {
  background: linear-gradient(90deg, #fff, #f2ebd8);

  &::before {
    content: '';
    background-repeat: repeat-x;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-image: url(../../images/wave-top.png);
    height: 150px;

  }
}

.footer-background2 {

  background-image: url("../../images/footer_bg.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.layout-prefooter {
  justify-content: space-between;
  //padding-top: 70px;
  //padding-bottom: 70px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 50px;

  .layout-item {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% / 2);
  }

  .grid-contact {
    display: flex;
    flex-direction: column;
    .grid-item:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

}

.card-contact {
  .card-title {
    font-size: 24px;
  }
  .card-list {
    padding-left: 0;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  .card-label {
    margin-right: 10px;
    text-transform: uppercase;
  }

}

.social-list {
  margin-bottom: 30px;
  padding-left: 0;
  list-style: none;
  display: flex;
  & > li {
    &:not(:last-child) {
      margin-right: 20px;
    }

  }

  a {
    span {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      width: 40px;
      padding: 10px;
      border-radius: 50%;
      //font-size: 22px;
      color: @global-secondary-color;
      position: relative;
      transition: all ease 0.2s;
      &::before {
        z-index: 1;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: 100%;
        z-index: 0;
        border-radius: 50px;
        background-color: @global-primary-background;
      }
      &:hover {
        color: #fff;
        font-size: 18px;
        &::after {
          animation: animate ease 0.6s forwards;
        }
      }
    }
    span.icon-facebook:hover {
      &::after {
        background-color: #3b5998;
      }
    }
    span.icon-twitter:hover {
      &::after {
        background-color: #00aced;
      }
    }

    span.icon-youtube-play:hover {
      &::after {
        background-color: #bb0000;
      }
    }
    span.icon-linkedin:hover {
      &::after {
        background-color: #007bb6;
      }
    }
    span.icon-google-plus:hover {
      &::after {
        background-color: #dd4b39;
      }
    }
  }
}

@keyframes animate {
  0%, 20% {
    transform: scale(1);
    border-radius: 30px;
    background-color: #9E9E9E;
  }
  30% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
    border-radius: 10px;
  }
  60%, 100% {
    transform: scale(1);
    border-radius: 5px;
  }
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  .copyright {
    font-size: 14px;
  }
}

@media all and (max-width: 1200px) {
  .card-contact {
    .card-list {
      font-size: 16px;
    }
  }
}
@media all and (max-width: 992px) {
  .card-contact {
    .card-list {
      font-size: 14px;
    }
  }
}

@media all and (max-width: 850px) {
  .footer-background2 {
    background-position: 40% 75%;
    background-size: auto;
  }

  .layout-prefooter {
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-bottom: 50px;

    .layout-item {
      width: calc(100% / 1);
      &:nth-child(1) {

      }
      &:nth-child(2) {
        max-width: 80%;
        margin: auto;
        margin-bottom: 70px;
      }
    }

    .grid-contact {
      display: flex;
      flex-direction: column;
      .grid-item:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 40px;
    }

  }

  .card-contact {
    text-align: center;
    .card-list {
      li {
        justify-content: center;
      }
    }
  }
}


@media all and (max-width: 768px) {

  .footer {
    padding-top: 140px;
  }
}

@media all and (max-width: 640px) {
.footer{
  padding-bottom: 40px;
}
  .layout-prefooter {
    margin-bottom: 40px;
    .layout-item {
      width: calc(100% / 1);
      &:nth-child(2) {
        max-width: 100%;
      }
    }

  }
}

@media all and (max-width: 480px) {
  .card-contact {
    .card-list {
      font-size: 12px;
    }
  }
  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    .copyright {
      font-size: 12px;
    }
  }
}
