.section-account {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  &::after {
    content: '';
    background-image: url(../../images/wave-bottom.png);
    background-repeat: repeat-x;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 139px;
    width: 100%;
  }
}

.layout-account {
  justify-content: flex-start;

  .layout-item:first-child {
    width: 300px;
    min-width: 250px;
    .media {
      margin-top: 50px;
    }
  }
  .layout-item:nth-child(2) {
    width: 100%;
    max-width: 700px;

  }
}

.account-background {
  background: linear-gradient(-108deg, #fff 0%, #f9f6ed 52%, #fff 100%);
}

.form-account {
  padding: 60px 70px 30px 70px;
  background-color: @global-background;
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .15);
  //text-align: center;

  .form-header,
  .form-footer {
    text-align: center;
  }
  .form-header {
    margin-bottom: 30px;
  }

  .sup-form-text {
    font-size: 14px;
    color: @global-muted-color;
  }
  .form-actions {
    margin-bottom: 30px;
  }
  .form-help {
    font-size: 16px;
    .description {
      margin-bottom: 10px;
    }
    a {
      text-transform: uppercase;
      color: @global-primary-color;
    }
  }
}

@media all and (max-width: 850px) {
  .layout-account {
    .layout-item:first-child {
      //width: 300px;
      min-width: 200px;
    }

  }

  .form-account {
    padding: 50px 40px 30px 40px;
  }
}

@media all and (max-width: 767px) {
  .layout-account {
    justify-content: center;
    .layout-item:first-child {
      display: none;
    }

    .layout-item:nth-child(2) {
      //margin-left: auto;
      //margin-right: auto;
    }

  }
}


@media all and (max-width: 480px) {

  .form-account {
    padding: 50px 20px 30px 20px;
  }
}