@global-font-family: 'ABeeZee', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
@global-primary-font-family: "Russo One", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

@global-font-size: 18px;
@global-line-height: 1.5;

@global-color: #333333;
@global-primary-color: #ff0000;
@global-secondary-color: #161616;
@global-inverse-color: #fff;
@global-muted-color: #666;

@global-link-color: inherit;
@global-link-hover-color: inherit;

@global-background: #fff;

@global-muted-background: #f9f6ed;
@global-primary-background: rgba(22, 22, 22, .1);
//@global-secondary-background:                   #2d2c32;

@global-success-background: #0f9b00;
@global-warning-background: #f55e41;
@global-danger-background: #b40000;

@global-border-width: 1px;
@global-border: #b7b7b7;

@global-margin: 20px;

@global-gutter: 30px;

@global-control-height: 48px;

@base-body-background: @global-background;
@base-body-font-family: @global-font-family;
@base-body-font-weight: normal;
@base-body-font-size: @global-font-size;
@base-body-line-height: @global-line-height;
@base-body-color: @global-color;

@base-link-color: @global-link-color;
@base-link-text-decoration: none;
@base-link-hover-color: @global-link-hover-color;
@base-link-hover-text-decoration: none;

@base-strong-font-weight: bolder;
@base-code-font-size: @global-font-size;
@base-code-font-family: Consolas, monaco, monospace;
@base-code-color: #f0506e;
@base-em-color: #f0506e;
@base-ins-background: #ffd;
@base-ins-color: @global-color;
@base-mark-background: #ffd;
@base-mark-color: @global-color;
@base-quote-font-style: italic;
@base-small-font-size: 80%;

@base-margin-vertical: @global-margin;

@base-heading-font-family: @global-primary-font-family;
@base-heading-font-weight: normal;
@base-heading-color: #161616;
@base-heading-text-transform: normal;
@base-heading-margin-top: @global-margin;
@base-h1-font-size: 60px;
@base-h1-line-height: 1.1;
@base-h2-font-size: 50px;
@base-h2-line-height: 1.3;
@base-h3-font-size: 40px;
@base-h3-line-height: 1.5;
@base-h4-font-size: 30px;
@base-h4-line-height: 1.5;
@base-h5-font-size: 24px;
@base-h5-line-height: 1.4;
@base-h6-font-size: 18px;
@base-h6-line-height: 1.4;

@base-list-padding-left: 30px;

@base-hr-margin-vertical: @global-margin;
@base-hr-border: @global-border-width solid @global-border;

@base-blockquote-color: #333;
@base-blockquote-font-size: @global-font-size;
@base-blockquote-line-height: 1.5;
@base-blockquote-font-style: italic;
@base-blockquote-margin-vertical: @global-margin;
@base-blockquote-footer-color: #333;
@base-blockquote-footer-margin-top: @global-margin;
@base-blockquote-footer-font-size: @global-font-size;
@base-blockquote-footer-line-height: 1.5;

@base-pre-font-size: @global-font-size;
@base-pre-line-height: 1.5;
@base-pre-font-family: @base-code-font-family;
@base-pre-color: @global-color;

@base-selection-background: #39f;
@base-selection-color: @global-inverse-color;

@button-color: @global-primary-color;
@button-background: none;
@button-font-family: inherit;
@button-font-size: @global-font-size;
@button-text-transform: uppercase;
@button-font-weight: inherit;
@button-padding: 6px 45px;
@button-big-padding: 6px 50px;
@button-height: @global-control-height;
@button-border: 2px solid @global-primary-color;
@button-border-radius: 5px;
@button-transition: all .2s ease;
@button-margin-top: 30px;

@button-color-hover: @global-inverse-color;
@button-background-hover: @button-background;
//@button-border-hover: @global-border-width solid @global-primary-color;

@button-color-focus: @global-inverse-color;
@button-background-focus: @button-background;
//@button-border-focus: @global-border-width solid @global-inverse-color;

@button-color-active: @global-inverse-color;
@button-background-active: @button-background;
//@button-border-active: @global-border-width solid @global-inverse-color;

button,
input[type="button"],
input[type="submit"],
.button {
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  user-select: none;
  overflow: hidden;
  font: inherit;
  text-transform: @button-text-transform;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: @button-padding;
  font-family: @button-font-family;
  font-size: @button-font-size;
  min-height: @button-height;
  text-decoration: none;
  background: @button-background;
  color: @button-color;
  border: @button-border;
  border-radius: @button-border-radius;
  transition: @button-transition;
  margin-top: @button-margin-top;
  z-index: 1;

  &.button-lg {
    padding: @button-big-padding;
    //text-transform: initial;
  }

  &::after {
    position: absolute;
    transition: .3s;
    content: '';
    width: 0;
    left: -35px;
    background: @global-primary-color;
    height: 120%;
    -webkit-transform: skewX(50deg);
    transform: skewX(50deg);
    z-index: -1;
    bottom: -2px;
    top: -2px;

  }

  &:hover,
  &:focus,
  &:active {
    &::after {
      width: 104%;
    }
  }
}

@form-height: @global-control-height;
@form-line-height: 1.4;
@form-padding: 0;

@form-background: transparent;
@form-color: @global-color;
@form-label-color: @global-color;
@form-border: 1px solid #b7b7b7;
@form-invalid-border: 1px solid @global-danger-background;
@form-valid-border: 1px solid @global-success-background;

@form-focus-background: @form-background;
@form-focus-color: @global-color;

@form-disabled-background: @global-background;
@form-disabled-color: @global-muted-color;

@form-placeholder-color: @global-muted-color;

@form-invalid-color: @global-danger-background;
@form-valid-color: @global-success-background;

@form-select-padding-right: 20px;
@form-select-option-color: #444;

@form-radio-size: 16px;
@form-radio-margin-top: -4px;
@form-radio-background: transparent;

@form-radio-checked-background: transparent;

@form-radio-checked-focus-background: transparent;

@form-radio-disabled-background: transparent;

//@form-legend-font-size:                         @global-font-size;
//@form-legend-line-height:                       1.4;

//@form-control-margin-bottom:                    @global-margin;
//
//@form-icon-width:                               @form-height;
//@form-icon-font-size:                           @global-font-size;
//@form-icon-color:                               @global-muted-color;
//@form-icon-hover-color:                         @global-color;
//
//@form-range-thumb-height:                       15px;
//@form-range-thumb-border-radius:                500px;
//@form-range-thumb-background:                   @global-color;
//
//@form-range-track-height:                       3px;
//@form-range-track-background:                   @global-muted-background;
//@form-range-track-focus-background:             @global-muted-background;

@internal-form-select-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="black"><path d="M12 1l-3 5h6z"></path><path d="M12 13l-3-5h6z"></path></svg>');
@internal-form-radio-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M10 8c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path></svg>');
@internal-form-checkbox-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" stroke="black"><path fill="none" stroke="inherit" stroke-width="1.8824" stroke-miterlimit="50" stroke-linecap="butt" stroke-linejoin="miter" d="M20.028 2.353l-12.028 12.028-6.381-6.381"></path></svg>');
@internal-form-checkbox-indeterminate-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M3 8h10v1h-10v-1z"></path></svg>');


input:not([type]),
input[type=date],
input[type=datetime-local],
input[type=email],
input[type=file],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type="month"],
input[type=week],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 5px;
  min-height: @form-height;
  background: @form-background;
  border-bottom: @form-border;
  color: @form-color;
  box-shadow: 0 0 0 0 transparent inset;
  border-radius: 0;

}

::placeholder {
  color: @form-placeholder-color;
  opacity: 1;
  //.hook-form-placeholder();
}

input:not([type]):focus,
input[type=date]:focus,
input[type=datetime-local]:focus,
input[type=email]:focus,
input[type=file]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type="month"]:focus,
input[type=week]:focus,
input[type=text]:focus,
input[type=time]:focus,
input[type=url]:focus,
textarea:focus,
select:focus {
  background: @form-focus-background;
  color: @form-focus-color;
  //.hook-form-focus();
}


input[type="radio"],
input[type="checkbox"] {
  display: inline-block;
  height: @form-radio-size;
  width: @form-radio-size;
  overflow: hidden;
  margin-top: @form-radio-margin-top;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: @form-radio-background;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50% 50%;
  border: @form-border;
}

lable {
  color: @form-label-color;
}


.form-group {
  position: relative;
  //&:not(:last-child) {
  margin-bottom: 40px;
  //}
  &.error {
    input {
      border-bottom: @form-invalid-border;
    }

    .form-message {
      color: @global-danger-background;
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
    }
  }

  &.success {
    input {
      border-bottom: @form-valid-border;
    }

    .label-materials {
      color: @global-success-background;
    }
  }

}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  font-size: 18px;
}

.label-materials {
  position: absolute;
  left: 5px;
  top: 10px;
  pointer-events: none;
  transition: all .2s;

  &.label-materials-is-active {
    top: -20px;
    font-size: 14px;
  }

}

form {
  font-size: 16px;
}

textarea {
  resize: none;
}

//
//input:invalid{
// border-bottom: @form-invalid-border;
//}
//input:invalid ~ .placeholder{
//color: @global-danger-background;
//}

//@global-success-background;

@table-color: @global-color;
@table-background: @global-background;

@table-margin-vertical: @global-margin;

@table-cell-padding: 16px;

@table-divider-border-width: @global-border-width;
@table-divider-border: @global-border-width solid @global-border;

@table-expand-min-width: 150px;

.overlay,
.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.65);
}

.foreground {
  position: relative;
  z-index: 1;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

h2 {
  &.is-inverse {
    font-family: @global-font-family;
  }
}

h2, h3, h4 {
  text-transform: uppercase;
}

.big-letter {
  text-transform: uppercase;
}

.heading {
  margin-bottom: 30px;

  &.heading-width {
    max-width: 920px;
  }

  &.center {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }

  &.m-b-lg {
    margin-bottom: 50px;
  }
}

.card {
  width: 100%;
}

.list-main {
  list-style-type: none;
  padding: 0;

  li {
    display: inline-flex;

    &::before {
      margin-right: 10px;
      content: '';
      display: flex;
      margin-top: 6px;
      /* padding-top: 23px; */
      align-items: stretch;
      height: 16px;
      /* align-items: center; */
      width: 18px;
      padding: 6px;
      ///* vertical-align: middle; */
      background-repeat: no-repeat;
      background-image: url(data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAh1BMVEUWFhb/////AAAWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhb5AQH/AAD/AAD/AAD/AAD/AAAWFhb/AAAWFhYWFhb/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAAWFhZiDw/IBQWYCgr/AABgDw/KBQUAAADPU7E7AAAAJXRSTlMAAABPwPW+TImBSrn27vT+9sBNhEqCubvv7MO4TEOH/n1Juu23b49f2AAAAAFiS0dELLrdcasAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfiCgQQBQbauG3fAAAAdElEQVQI113ORwKCUBRD0fxQRaWIDRWwgIDuf3/+9ibe2RklAIIwiuIEOqUUkI62lXcw+jLnULymCRvx1jl3ek9FWe20Y8t5sdVEYvhZfHvgoP0VH/VqdjoX4sbcIss/X8RX84m8iVtndlb9nd58PF/N0JI/CO0Uon54WrAAAAAASUVORK5CYII=);
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.scroll-top {
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 17px;
  background-color: @global-muted-background;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  right: 15px;
  bottom: 80px;
  transform: translate(0, 200px);
  transition: all .3s ease-out;
  border-radius: 3px;

    &.active {
      transform: none;
    }
  }


  //body {
  //  font-size: 16px;
  //  background-color: @global-muted-background;
  //}

  //.hook-form() {
  //  & {border-bottom: @global-border-width solid @global-border;}
  //
  //  &:hover {
  //    background-color: #ebebeb;
  //  }
  //}

  //.hook-form-placeholder() {
  //  opacity: 0.5;
  //}

  //.hook-form-focus() {
  //  border-bottom-color: #017eff;
  //  &::placeholder {
  //    color: @global-link-color;
  //    opacity: 1;
  //    margin-bottom: 10px;
  //  }
  //}
  //
  //.hook-form-disabled() {
  //  &::placeholder {
  //    opacity: 0.1;
  //  }
  //}

  //.hook-component() {
  //  .inverse {
  //    background-color: #000;
  //    color: #fff;
  //    svg {color: #fff;
  //    }
  //    a {color: #fff}
  //    h1, h2, h3, h4, h5, h6 {color: #fff}
  //  }
  //}

  body {
    overflow-x: hidden;
  }

  @media all and (max-width: 1200px) {
    h1 {
      font-size: 50px;
    }

    h2 {
      font-size: 48px;
    }

  }

  @media all and (max-width: 850px) {
    .list-main {
      li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
  @media all and (max-width: 768px) {
    button,
    input[type="button"],
    input[type="submit"],
    .button {
      &.button-big {
        padding: @button-padding;
      }
    }

    h1 {
      font-size: 42px;
    }

    h2 {
      font-size: 38px;
    }


  }

  @media all and (max-width: 640px) {
    h1 {
      font-size: 38px;
    }

    h1 {
      font-size: 35px;
    }
  }